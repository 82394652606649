<!--
 * @Author: jiang
 * @Date: 2021-07-13 11:18:51
 * @Description: 领导日程详情
-->
<template>
  <el-form
    ref="form"
    :model="formData"
    :rules="formRules"
    label-position="top"
  >
    <el-row :gutter="20">
      <el-col>
        <el-form-item
          label="所在部门"
          prop="department_id"
        >
          <select-dept
            v-model="formData.department_id"
            @change="onChange"
          ></select-dept>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item
          label="姓名"
          prop="user_id"
        >
          <el-select
            v-model="formData.user_id"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item
          label="时间"
          prop="schedule_date"
        >
          <el-date-picker
            v-model="formData.schedule_date"
            value-format="yyyy-MM-dd"
            placeholder="请选择"
          ></el-date-picker>
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item
          label="内容"
          prop="content"
        >
          <el-input
            v-model="formData.content"
            type="textarea"
            :autosize="{minRows: 3}"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <div class="g-center g-mt-20">
      <el-button
        :loading="loading"
        type="primary"
        @click="onSave"
        icon="el-icon-plus"
      >保存</el-button>
    </div>
  </el-form>
</template>

<script>
import SelectDept from '@/components/select/cascader/dept.vue'
import { getUserList } from '@/api/user/index'
import { createReport, updateReport } from '@/api/report'

export default {
  components: {
    SelectDept,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      loading: false,
      userList: [],
      formData: {
        department_id: '',
        user_id: '',
        schedule_date: '',
        content: '',
      },
      formRules: {
        department_id: { required: true, message: '必填项', trigger: 'change' },
        user_id: { required: true, message: '必填项', trigger: 'change' },
        schedule_date: { required: true, message: '必填项', trigger: 'change' },
        content: { required: true, message: '必填项', trigger: 'blur' },
      },
    }
  },
  watch: {
    data: {
      handler(val) {
        if (val) {
          this.formData = this.$tools.copy(val)
          this.$nextTick(() => {
            this.$refs.form.clearValidate()
            this.onChange(val.department_id, 'init')
          })
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onChange(id, flag) {
      const params = {
        department_id: id,
      }
      if (flag !== 'init') {
        this.formData.user_id = ''
      }
      getUserList(params).then(res => {
        this.userList = res
      })
    },
    onSave() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          if (this.formData.id) {
            updateReport(this.formData.id, this.formData)
              .then(res => {
                this.$message.success('更新成功')
                this.$emit('success')
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            createReport(this.formData)
              .then(res => {
                this.$message.success('新建成功')
                this.$emit('success')
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    },
  },
}
</script>
